import React from "react"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import PoemsContainer from "@components/PoemsPage"

function PoemsPage() {
  return (
    <Layout>
      <SEO title="Poems" />
      <PoemsContainer />
    </Layout>
  )
}

export default PoemsPage
