import React from 'react'
import './PoemsPage.css'
import getAllPoems from './poems'
// import BackgroundImage from '../../images/background.jpg'

function shuffle(array) {
  array.sort(() => Math.random() - 0.5)
}

function PoemsPage() {

  const poems = getAllPoems();
  shuffle(poems)

  return (
    <div>
      <div class="horizontal-line" />
      <div class="box">
        {
          poems.map(poem =>
            <div class="item">
              <h1>{poem.title}</h1>
              <h2>By Sara Curry</h2>
              {poem.text}
            </div>
          )
        }
      </div>
    </div>
  )
}

export default PoemsPage